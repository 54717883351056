import { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import Header from "./Header";
const loadOrder = () => import("./Order");
const Order = lazy(loadOrder);
import Login from "./Login";
import Home from "./Home";

import { AuthContext, useAuth, useAuthInternal } from "./auth";

// import Register from "./Register";

function loadPages() {
	loadOrder();
}

function App() {
	const auth = useAuthInternal();

	useEffect(
		() => {
			setTimeout(
				() => {
					loadPages();
				},
				1000
			);
		},
		[]
	);
	
	return (
		<AuthContext.Provider value={auth}>
			<Router>
				<Header />
				<Routes>
					<Route path="/" element={auth.username ? <Home /> : <Login />} />
					<Route path="/order" element={<ProtectedRoute><Suspense><Order /></Suspense></ProtectedRoute>} />
					{/* <Route path="/register" element={<Register />} /> */}
				</Routes>
			</Router>
		</AuthContext.Provider>
	)
}
	
export default App;

function ProtectedRoute({ children }) {
	const { username, initialized } = useAuth();
	if (!initialized) {
		return null;
	}

	if (!username) {
		return <Navigate to="/" />;
	}
	
	return children;
}