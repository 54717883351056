import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import Button from "./Button";
import FileList from "./FileList";

import { generateOrderNameBasedOnFiles } from "../utils";

import "./OrderList.css";

function OrderList(props) {
    const {
        orders,
        // color,
        // secondaryColor,
    } = props;

    const navigate = useNavigate();

    const sortedOrders = orders?.sort((oA, oB) => -dayjs(oA.configuration?.requestedDeliveryDate).diff(oB.configuration?.requestedDeliveryDate)) ?? [];
    sortedOrders.forEach((order) => {
        // TEMP HACK: Add 2 hours to order.created because backend is using UTC without timezone info
        if (!order._timeFixed) {
            order.created = dayjs(order.created).add(2, "hour").toISOString();
            order._timeFixed = true;
        }
    });

    return (
        <div className="order-list">
            <div className="heading">
                <h1>My orders</h1>
                <Button
                    label="Create order"
                    onClick={() => {
                        navigate("/order");
                    }}
                />
            </div>
            {orders !== null && !orders?.length && (
                <p className="top-info">No orders yet.</p>
            )}
            {orders?.length > 0 && (
                <p className="top-info">Here are your orders, click on an order to see more info.</p>
            )}
            <div className="orders">
                {sortedOrders.map((order) => {
                    return (
                        <Order
                            key={order.orderGuid ?? order.id}
                            order={order}
                        />
                    );
                })}
            </div>

            {orders?.length > 0 && (
                <p>Do you need to modify an order?<br/>Please contact us at <a href="mailto:team@junefirst.tv">team@junefirst.tv</a>.</p>
            )}
        </div>
    );
}

export default OrderList;

function Order({ order }) {
    const [expand, setExpand] = useState(false);
    const orderEl = useRef(null);

    useEffect(
        () => {
            if (Math.abs(dayjs(order.created).diff()) < 2000) {
                setTimeout(() => {
                    orderEl.current?.classList.add("new-order");
        
                    setTimeout(() => {
                        orderEl.current?.classList.remove("new-order");
                    }, 3000);
                });
            }
        },
        [orderEl, order]
    );

    return (
        <div className="order" ref={orderEl}>
            <div className="order-info" onClick={() => setExpand(!expand)}>
                <div className="order-name">{generateOrderNameBasedOnFiles(order.configuration?.files, "Empty order")}</div>
                <div className="order-details">
                    <div className="order-requested-delivery-date">Requested delivery by {dayjs(order.configuration.requestedDeliveryDate).format("YYYY-MM-DD")}</div>
                    {expand && (
                        <div>
                            <div className="order-created">Created {dayjs(order.created).format("YYYY-MM-DD HH:mm")}</div>
                            <div className="order-id">Order ID: {order.orderGuid ?? order.id}</div>
                            <div>
                                <Button
                                    label="Cancel order"
                                    size="small"
                                    color="var(--color-4-primary)"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        const reason = window.prompt("Please provide a reason for cancelling this order:");
                                        if (reason) {
                                            console.log("reason", reason);
                                            window.alert("Order cancelled. (This is a mockup, so nothing really happened.)");
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {expand && (
                <div className="order-files">
                    <FileList
                        files={order.configuration?.files}
                        includeDetails={false}
                    />
                </div>
            )}
        </div>
    );
}
