import { useRef } from "react";
import "./Text.css";

function Text(props) {
    const {
        type = "text",
        ...rest
    } = props;

    const inputRef = useRef();

    return (
        <div
            className="text"
            onClick={() => {
                inputRef.current.focus();
            }}
        >
            <div className="label-container">
                <label>{props.label}</label>
            </div>
            {type === "textarea" && (
                <textarea
                    spellCheck="false"
                    ref={inputRef}
                    {...rest}
                />
            )}
            {type !== "textarea" && (
                <input
                    type={type}
                    spellCheck="false"
                    ref={inputRef}
                    {...rest}
                />
            )}
        </div>
    );
}

export default Text;