import OrderList from "./components/OrderList";

import { useOrders } from "./hooks/useOrders";

import "./Home.css";

function Home() {

    const { items: orders/*, remove: removeOrder*/ } = useOrders();

    // const onRemoveOrder = (orderId) => {
    //     if (window.confirm("Are you sure you want to remove this order?")) {
    //         removeOrder(orderId);
    //     }
    // };

    return (
        <div className="page page-home">
            <OrderList
                orders={orders}
            />
            
        </div>
    );
}

export default Home;