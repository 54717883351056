import "./Button.css";

function Button(props) {
    const {
        onClick,
        color,
        label,
        children,
        style,
        size = "medium",
        className = "",
        ...rest
    } = props;

    return (
        <button
            className={`button size-${size} ${className}`}
            onClick={onClick}
            style={{
                ...style,
                "--button-bg": color,
            }}
            {...rest}
        >
            {label ?? children}
        </button>
    );
}

export default Button;