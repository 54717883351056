import { useCallback, useEffect, useState } from "react";
import { getAllOrders, removeOrder, createOrder } from "../api";

export function useOrders() {
    const [items, setItems] = useState(null);

    useEffect(
        () => {
            (async () => {
                const orders = await getAllOrders();
                setItems(orders);
            })();
        },
        []
    );

    const remove = useCallback(
        async (id) => {
            await removeOrder(id);
            setItems((items) => items.filter((item) => item.orderId !== id));
        },
        []
    );

    const add = useCallback(
        async (data) => {
            const order = await createOrder(data);
            setItems((items) => [...items, order]);
        },
        []
    );

    return {
        items,
        add,
        remove,
    };
}