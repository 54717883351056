import axios from "axios";
import { API_BASE_URL } from "./config";
import { APITOKEN_STORAGE_KEY_PREFIX, USERTOKEN_STORAGE_KEY, validateToken } from "./auth";

const ORDERS_API = "orders_prev";
const SHIELD_API = "shield";

export async function createOrder(data) {
    return await post({
        api: ORDERS_API,
        path: "orders/import",
        data,
    });
}

export async function getAllOrders() {
    return await get({
        api: ORDERS_API,
        path: "orders",
    });
}

export async function removeOrder(id) {
    return await del({
        api: ORDERS_API,
        path: `orders/${id}`,
    });
}

export async function authenticate(data) {
    return await post({
        api: SHIELD_API,
        path: "logins/login",
        data,
        skipAuth: true,
    });
}

// export async function register(data) {
//     return await post({
//         path: "shield/logins/register",
//         data,
//         skipAuth: true,
//     });
// }

export async function createFile(data) {
    return await post({
        api: ORDERS_API,
        path: `files`,
        data,
    });
}

const get = async (args) => await makeRequest("get", args);
const put = async (args) => await makeRequest("put", args);
const post = async (args) => await makeRequest("post", args);
const del = async (args) => await makeRequest("delete", args);

async function makeRequest(method, { api, path, data, skipAuth = false }) {
    let authHeader = null;
    if (!skipAuth) {
        const apiToken = await getApiToken(api);
        authHeader = `Bearer ${apiToken}`;
    }
    const result = await axios({
        method,
        url: `${API_BASE_URL}/${api}/${path}`,
        data,
        headers: {
            "Content-Type": "application/json",
            "Authorization": authHeader,
        },
    });
    return result.data?.result ?? result.data;
}

async function getApiToken(api) {
    const apiTokenStorageKey = `${APITOKEN_STORAGE_KEY_PREFIX}${api}`;
    const jwt = localStorage.getItem(apiTokenStorageKey);
    if (jwt && validateToken(jwt)) {
        return jwt;
    }

    // Get a new token if we don't have one
    const userToken = localStorage.getItem(USERTOKEN_STORAGE_KEY);
    const result = await axios({
        method: "get",
        url: `${API_BASE_URL}/shield/services/${api}/token`,
        headers: {
            "Authorization": `Bearer ${userToken}`,
        },
    });

    if (result?.data) {
        localStorage.setItem(apiTokenStorageKey, result.data);
        return result.data;
    }

    return null;
}