import { useState, createContext, useCallback, useContext, useEffect, useRef } from "react";
import { jwtDecode } from "jwt-decode";
import * as API from "./api";

export const USERTOKEN_STORAGE_KEY = "usertoken";
export const APITOKEN_STORAGE_KEY_PREFIX = "apitoken-";
const TOKEN_PADDING = 1000 * 60 * 5;
const TOKEN_CHECK_INTERVAL = 1000 * 60;

export function useAuthInternal() {

    const checkTokenInterval = useRef();
    const [userToken, setUserToken] = useState(null);
    const [initialized, setInitialized] = useState(false);

    useEffect(
        () => {
            const token = getUserFromStorage();
            const validToken = validateToken(token);
            if (validToken) {
                setUserToken(token);
            }

            setInitialized(true);

            checkTokenInterval.current = setInterval(
                () => {
                    const token = getUserFromStorage();
                    const validToken = validateToken(token);
                    if (!validToken) {
                        setUserToken(null);
                        localStorage.removeItem(USERTOKEN_STORAGE_KEY);
                        clearInterval(checkTokenInterval.current);
                    }
                },
                TOKEN_CHECK_INTERVAL
            );

            return () => {
                clearInterval(checkTokenInterval.current);
            };
        },
        []
    );

    const login = useCallback(
        async (data) => {
            const response = await API.authenticate(data);
            setUserToken(response);
            localStorage.setItem(USERTOKEN_STORAGE_KEY, response);
        },
        [],
    );

    const logout = useCallback(
        (navigate) => {
            setUserToken(null);
            localStorage.removeItem(USERTOKEN_STORAGE_KEY);
            Object.keys(localStorage)
                .filter(key => key.startsWith(APITOKEN_STORAGE_KEY_PREFIX))
                .forEach(key => localStorage.removeItem(key));
            if (navigate) {
                navigate("/");
            }
        },
        []
    );

    // const register = useCallback(
    //     async (data) => {
    //         const response = await API.register(data);
    //         setUserToken(response);
    //         localStorage.setItem(USERTOKEN_STORAGE_KEY, response);
    //     },
    //     [],
    // );

    return {
        initialized,
        login,
        logout,
        // register,
        userToken,
        ...extractInfoFromToken(userToken)
    };
}

export const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export function validateToken(token) {
    try {
        const decoded = jwtDecode(token);
        return (decoded.exp * 1000) > (Date.now() + TOKEN_PADDING);
    } catch (error) {
        console.log("Error validating token", error);
        return false;
    }
}

function getUserFromStorage() {
    try {
        const user = localStorage.getItem(USERTOKEN_STORAGE_KEY);
        return user;
    } catch (error) {
        console.log("Error loading user from storage", error);
        localStorage.removeItem(USERTOKEN_STORAGE_KEY);
        return null;
    }
}

function extractInfoFromToken(token) {
    try {
        const decoded = jwtDecode(token);
        return {
            username: decoded.username,
            roles: decoded.accessroles,
        };
    } catch (error) {
        return {
            username: null,
            roles: [],
        };
    }
}

export function getUserRoles() {
    const { roles } = extractInfoFromToken(getUserFromStorage());
    return roles;
}