import { useState } from "react";
// import { Link } from "react-router-dom";

import Button from "./components/Button";
import Text from "./components/Text";
import { useAuth } from "./auth";

import "./Login.css";

function Login() {
    const { login } = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = async () => {
        try {
            setIsLoading(true);
            await login({
                name: email,
                password,
            });
        } catch {
            // TODO: Show real error message in the form, not window.alert
            window.alert("An error occured while logging in, please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const onTextKeyPress = (e) => {
        if (e.key === "Enter") {
            handleLogin();
        }
    };

    return (
        <div className="page page-login">
            <h1>Login</h1>
            <Text
                label="E-mail"
                type="text"
                name="email"
                value={email}
                onKeyPress={onTextKeyPress}
                onChange={(e) => {
                    setEmail(e.target.value);
                }}
            />
            <Text
                label="Password"
                type="password"
                name="password"
                value={password}
                onKeyPress={onTextKeyPress}
                onChange={(e) => {
                    setPassword(e.target.value);
                }}
            />
            <div className="actions">
                <Button
                    label="Log in"
                    onClick={handleLogin}
                    style={{ width: "100%" }}
                    disabled={isLoading}
                />
                {/* <Link to="/register">
                    Register
                </Link> */}
            </div>
            <hr/>
            <div className="actions">
                <Button
                    label="Log in with Microsoft"
                    onClick={() => {}}
                    disabled
                    style={{ width: "100%" }}
                />
            </div>
        </div>
    );
}

export default Login;